@import url("https://fonts.cdnfonts.com/css/tt-commons");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "TT Commons", sans-serif !important;
}

ul {
  list-style: none;
}
