.mobile-container {
    width: 100%;
    height: 400px;
    overflow-y: auto;
}
.mobile-cart-container {
    width: 100%;
    height: 100%;
    font-size: 30px;
    margin-bottom: 2rem;
  }
.mobile-cart-container p {
  margin-bottom: 0;
}
.mobile-order-container {
  border: rgba(189, 202, 247, 0.6) 1.25px solid;
  height: 90%;
  border-radius: 20px 0px 20px 20px;
  display: flex;
  flex-direction: column;
  padding: 0px 15px 0px 15px;
}
.mobile-order-container span {
  color: #2f3356;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.mobile-order-container p {
  color: #444790;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.mobile-delete-icon {
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: right;
}
.mobile-icon {
  border: 1px #bdcaf7 solid;
  border-radius: 10px 10px 0px 0px;
  transform-origin: 0;
  height: 100%;
  width: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-event-box {
  height: 100%;
  display: flex;
  border-bottom: 1px rgba(189, 202, 247, 0.6) solid;
}
.mobile-event-date-time p {
  color: #1f2349;
  font-size: 13px;
  font-weight: 300;
  text-transform: capitalize;
}
.mobile-ticket-box {
  display: flex;
  align-items: center;
  border-bottom: 1px rgba(189, 202, 247, 0.6) solid;
  height: calc(100% / 5);
}

.mobile-price-box {
  display: flex;
  align-items: center;
  border-bottom: 1px rgba(189, 202, 247, 0.6) solid;
  height: calc(100% / 5);
}
.mobile-quantity-box {
  display: flex;
  align-items: center;
  border-bottom: 1px rgba(189, 202, 247, 0.6) solid;
  height: calc(100% / 5);
}
.mobile-total-box {
  display: flex;
  align-items: center;
  height: calc(100% / 5);
}
