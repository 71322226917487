.footer_container {
  background: rgba(31, 35, 73, 1);
  z-index: 5;
}
.inner_footer_box {
  width: 80%;
  height: 60%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logos {
  margin-top: 30%;
  display: flex;
  gap: 1rem;
}
.copywrite {
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(18, 22, 62, 1);
}
.copywrite_inner_box {
  width: 80%;
  text-align: right;
}
.copywrite_inner_box > p {
  color: #57bec0;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

@media (min-width: 320px) and (max-width: 767px) {
  .footer_container {
    display: none;
  }
}
