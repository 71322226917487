.toggle_label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.toggle_input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle_span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border: 1px solid #64c3c5;
  transition: 0.3s;
  border-radius: 30px;
}

.toggle_span:before {
  position: absolute;
  content: "";
  height: 34px;
  width: 34px;
  left: 0;
  bottom: -3px;
  background-color: #64c3c5;
  border-radius: 50%;
  transition: 0.3s;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
}

.toggle_input:checked + span {
  background-color: #64c3c5;
  border: 1px solid #fff;
}

.toggle_input:checked + span:before {
  transform: translateX(25px);
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
}

.toggle_strong {
  position: absolute;
  left: 100%;
  width: max-content;
  line-height: 30px;
  margin-left: 10px;
  cursor: pointer;
}
