.modal-content {
    border-radius: 40px;
}
.mobile-cart_modal .btn-close {
    background-image: url("data:image/svg+xml,<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M1.30723 11.7757C1.00818 12.0748 0.523328 12.0748 0.224288 11.7757C-0.0747625 11.4767 -0.0747625 10.9918 0.224288 10.6928L4.9171 6L0.224288 1.30723C-0.0747625 1.00818 -0.0747625 0.523328 0.224288 0.224288C0.523328 -0.0747625 1.00818 -0.0747625 1.30723 0.224288L6 4.9171L10.6928 0.224288C10.9918 -0.0747625 11.4767 -0.0747625 11.7757 0.224288C12.0748 0.523328 12.0748 1.00818 11.7757 1.30723L7.0829 6L11.7757 10.6928C12.0748 10.9918 12.0748 11.4767 11.7757 11.7757C11.4767 12.0748 10.9918 12.0748 10.6928 11.7757L6 7.0829L1.30723 11.7757Z\" fill=\"%2364C3C5\"/></svg>") !important;
    background-color: #d5eeef;
    opacity: 1;
    margin: 0;
    align-self: flex-start;
}
.mobile-cart_modal .modal-title {
    width: 100%;
    color: #1f2349;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 165.523%;
    text-transform: capitalize;
}
.mobile-table{
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-bottom: 0.80px rgba(68, 71, 144, 0.30) solid;
    padding-bottom: 10px;
}
.mobile-table-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mobile-table-details > p {
    margin: 0;
    color: #444790;
    max-width: 130px;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
}
.mobile-table > span {
    
}
.mobile-table-details > span {
    margin: 0;
    color: #57BABC;
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
}
.mobile-total-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}
.mobile-total-box > p {
    margin-bottom: 0;
    color: #1F2349;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}
.mobile-total-box > span {
    margin-bottom: 0;
    color: #57BABC;
    font-size: 32px;
    font-weight: 700;
    text-transform: capitalize;
}
.modal-cart_btn {
    background: #444790;
    border-radius: 40px;
    color: white;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-transform: capitalize;
}
